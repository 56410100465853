<template>
    <item-text title="Kredītrēķina nr." :text="item.uuid" />

    <item-text title="Kredītrēķina datums" :text="item.date" />

    <div class="w-full flex flex-wrap my-2 items-start">
        <item-text title="Klients">
            <template v-slot:content>
                <router-link :to="`/customers/${item.customer.id}`" class="mt-2 flex items-center text-md font-semibold text-gray-900 dark:text-gray-300 sm:mt-0 hover:text-main4 dark:hover:text-main4">
                    {{item.customer.name}}
                </router-link>
            </template>
        </item-text>
    </div>

    <div class="w-full flex flex-wrap my-2 items-start">
        <item-text title="Pozīcijas" :text="item.credit_note_items_count" />
        <item-text title="Kopā EUR" :text="item.total" />
        <item-text title="PVN EUR" :text="item.vat" />
        <item-text title="Summa EUR" :text="item.total_with_vat" />
        <item-text title="Reversais PVN" :text="item.reverse_vat ? 'Jā' : 'Nē'" />

        <template v-if="item.notes">
            <div class="w-full mt-5">
                <item-text title="Piezīmes" :text="item.notes" />
            </div>
        </template>
    </div>

</template>

<script>

import ItemText from "@/components/Components/ItemText"

export default {
    name: "ShowCreditNoteDetails",
    components: {
        ItemText,
    },
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped>

</style>